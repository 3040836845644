/*
 * SonarQube
 * Copyright (C) 2009-2023 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
.icon-checkbox {
  display: inline-block;
  line-height: 1;
  vertical-align: top;
  padding: 1px 2px;
  box-sizing: border-box;
}

a.icon-checkbox {
  border-bottom: none;
}

.icon-checkbox:focus {
  outline: none !important;
}

.icon-checkbox:before {
  content: ' ';
  display: inline-block;
  width: 10px;
  height: 10px;
  border: 1px solid #236a97;
  border-radius: 2px;
  transition: border-color 0.2s ease, background-color 0.2s ease, background-image 0.2s ease,
    box-shadow 0.4s ease;
}

.icon-checkbox:not(.icon-checkbox-disabled):focus:before,
.link-checkbox:not(.disabled):focus:focus .icon-checkbox:before {
  box-shadow: 0 0 0 3px rgba(35, 106, 151, 0.25);
}

.icon-checkbox-checked:before {
  background-color: #4b9fd5;
  background-image: url('data:image/svg+xml,%3Csvg%20viewBox%3D%220%200%2014%2014%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20stroke-linejoin%3D%22round%22%20stroke-miterlimit%3D%221.414%22%3E%3Cpath%20d%3D%22M12%204.665c0%20.172-.06.318-.18.438l-5.55%205.55c-.12.12-.266.18-.438.18s-.318-.06-.438-.18L2.18%207.438C2.06%207.317%202%207.17%202%207s.06-.318.18-.44l.878-.876c.12-.12.267-.18.44-.18.17%200%20.317.06.437.18l1.897%201.903%204.233-4.24c.12-.12.266-.18.438-.18s.32.06.44.18l.876.88c.12.12.18.265.18.438z%22%20fill%3D%22%23fff%22%20fill-rule%3D%22nonzero%22%2F%3E%3C%2Fsvg%3E');
  border-color: #4b9fd5;
}

.icon-checkbox-checked.icon-checkbox-single:before {
  background-image: url('data:image/svg+xml,%3Csvg%20viewBox%3D%220%200%2014%2014%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20stroke-linejoin%3D%22round%22%20stroke-miterlimit%3D%221.414%22%3E%3Cpath%20d%3D%22M10%204.698C10%204.312%209.688%204%209.302%204H4.698C4.312%204%204%204.312%204%204.698v4.604c0%20.386.312.698.698.698h4.604c.386%200%20.698-.312.698-.698V4.698z%22%20fill%3D%22%23fff%22%2F%3E%3C%2Fsvg%3E');
}

.icon-checkbox-disabled:before {
  border: 1px solid #bbb;
  cursor: not-allowed;
}

.icon-checkbox-disabled.icon-checkbox-checked:before {
  background-color: #bbb;
}

.icon-checkbox-invisible {
  visibility: hidden;
}

.link-checkbox {
  color: inherit;
  border-bottom: none;
}

.link-checkbox.disabled,
.link-checkbox.disabled:hover,
.link-checkbox.disabled label {
  color: #656565;
  cursor: not-allowed;
}

.link-checkbox:hover,
.link-checkbox:active,
.link-checkbox:focus {
  color: inherit;
}
