/*
 * SonarQube
 * Copyright (C) 2009-2023 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
.branch-like-navigation-toggler {
  padding: 4px 8px;
  border: 1px solid transparent;
  border-radius: 2px;
}

.branch-like-navigation-toggler:hover {
  border-color: rgba(0, 0, 0, 0.38);
  color: inherit !important;
}

.branch-like-navigation-toggler:active,
.branch-like-navigation-toggler.open {
  border-color: #236a97;
}

.branch-like-navigation-toggler-container {
  height: 26px;
}

.branch-like-navigation-toggler-container .popup {
  min-width: 430px;
  max-width: 650px;
}

.branch-like-navigation-menu .search-box-container {
  padding: 8px;
}

.branch-like-navigation-menu .search-box-container .search-box,
.branch-like-navigation-menu .search-box-container .search-box-input {
  max-width: initial !important;
}

.branch-like-navigation-menu .item-list {
  padding-bottom: 8px;
  max-height: 300px;
  overflow-y: auto;
}

.branch-like-navigation-menu .item {
  padding: 4px 8px;
}

.branch-like-navigation-menu .item.header {
  color: #656565;
}

.branch-like-navigation-menu .item:not(.header):hover,
.branch-like-navigation-menu .item:not(.header).active {
  background-color: #f3f3f3;
  cursor: pointer;
}

.branch-like-navigation-menu .hint-container {
  padding: 8px;
  background-color: #f3f3f3;
  border-top: 1px solid #e6e6e6;
}
