/*
 * SonarQube
 * Copyright (C) 2009-2023 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
.line-tooltip {
  fill: none;
  stroke: #656565;
  stroke-width: 1px;
  shape-rendering: crispEdges;
}

.chart-mouse-events-overlay {
  fill: none;
  stroke: none;
  pointer-events: all;
}

.chart-zoomed .line-chart-area {
  clip-path: url(#chart-clip);
}

.chart-zoomed .line-chart-path {
  clip-path: url(#chart-clip);
}

.chart-zoomed .leak-chart-rect {
  clip-path: url(#chart-clip);
}

.line-chart-dot {
  fill: #4b9fd5;
}

.line-chart-dot.line-chart-dot-1 {
  fill: #236a97;
}

.line-chart-dot.line-chart-dot-2 {
  fill: #24c6e0;
}

.line-chart-event {
  fill: #fff;
  stroke: #4b9fd5;
  stroke-width: 2px;
}

.line-chart-event.VERSION {
  stroke: #4b9fd5;
}

.line-chart-event.QUALITY_GATE {
  stroke: #00aa00;
}

.line-chart-event.QUALITY_PROFILE {
  stroke: #ed7d20;
}

.line-chart-event.OTHER {
  stroke: #9139d4;
}

.new-code-legend {
  fill: #656565;
  font-size: 12px;
}
