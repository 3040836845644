/*
 * SonarQube
 * Copyright (C) 2009-2023 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
.navbar-search {
  position: relative;
  padding: 8px 0;
}

.navbar-search .search-box,
.navbar-search .search-box-input {
  width: 26vw;
  max-width: 310px;
  min-width: 260px;
  height: 32px;
}

.navbar-search .search-box-input {
  border-color: #fff;
}

.navbar-search .search-box-note {
  line-height: 30px;
}

.navbar-search .search-box-magnifier,
.navbar-search .search-box-clear {
  top: 8px;
}

.navbar-search-input {
  vertical-align: middle;
  width: 310px;
  margin-top: 3px;
  margin-bottom: 3px;
  padding-left: 26px !important;
}

.navbar-search-input-hint {
  position: absolute;
  top: 1px;
  right: 27px;
  line-height: 24px;
  font-size: 12px;
  color: #656565;
}

.navbar-search-icon {
  position: relative;
  z-index: 3;
  vertical-align: middle;
  width: 16px;
  margin-left: 4px;
  margin-right: -20px;
  background-color: #fff;
  color: #656565;
}

.navbar-search-icon:before {
  font-size: 14px;
}

.navbar-search-item-match {
  flex-grow: 5;
  overflow: hidden;
  text-overflow: ellipsis;
}

.navbar-search-item-right {
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
}

.navbar-search-item-icons {
  position: relative;
  flex-shrink: 0;
  width: 16px;
  height: 16px;
}
.navbar-search-item-icons > * {
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
}

.navbar-search-item-icons > .icon-outline,
.navbar-search-item-icons > .icon-clock {
  z-index: 6;
  top: -4px;
  left: -5px;
}

.navbar-search-no-results {
  margin-top: 4px;
  padding: 5px 10px;
}

.global-navbar-search-dropdown {
  top: 100% !important;
  max-height: 80vh;
  width: 440px;
  padding: 0 !important;
  overflow-y: auto;
  overflow-x: hidden;
}

.global-navbar-search-dropdown .dropdown-bottom-hint {
  margin-bottom: 0;
}
