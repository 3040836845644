/*
 * SonarQube
 * Copyright (C) 2009-2023 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
.hotspot-list-header {
  padding: 16px 8px;
}

.hotspot-risk-header {
  padding: 8px;
}

.hotspot-category {
  background-color: white;
  border: 1px solid #e6e6e6;
}

.hotspot-category .hotspot-category-header {
  width: 100%;
  padding: 16px 8px;
  color: #333;
  border-bottom: none;
  border-left: 4px solid;
  box-sizing: border-box;
}

.hotspot-category strong {
  text-align: left;
}

.hotspot-category .hotspot-category-header:hover,
.hotspot-category .hotspot-category-header.contains-selected-hotspot {
  color: #4b9fd5;
}

.hotspot-category.HIGH .hotspot-category-header {
  border-left-color: #F0878E;
}

.hotspot-category.MEDIUM .hotspot-category-header {
  border-left-color: #F69D53;
}

.hotspot-category.LOW .hotspot-category-header {
  border-left-color: #F4D348;
}

.hotspot-category .hotspot-item {
  color: #333;
  display: block;
  padding: 8px 16px;
  border: 2px solid transparent;
  border-top-color: #e6e6e6;
  transition: padding 0s, border 0s;
  width: 100%;
  text-align: left;
}

.hotspot-category button.hotspot-item:focus {
  color: #333;
}

.hotspot-category .hotspot-item:hover {
  background-color: #f2faff;
  border: 2px dashed #4b9fd5;
  color: #333;
}

.hotspot-category .hotspot-item.highlight:hover {
  background-color: transparent;
}

.hotspot-category .hotspot-item.highlight {
  color: #333;
  border: 2px solid #4b9fd5;
  cursor: unset;
}

.hotspot-risk-badge {
  text-transform: uppercase;
  display: inline-block;
  text-align: center;
  padding: 0 4px;
  font-weight: bold;
  border-radius: 3px;
}

.hotspot-risk-badge.HIGH {
  color: rgba(0, 0, 0, 0.87);
  background-color: #F0878E;
}
.hotspot-risk-badge.MEDIUM {
  color: rgba(0, 0, 0, 0.87);
  background-color: #F69D53;
}
.hotspot-risk-badge.LOW {
  color: rgba(0, 0, 0, 0.87);
  background-color: #F4D348;
}

.hotspot-box-filename {
  direction: rtl;
}

.hotspot-header .issue-message-highlight-CODE {
  background-color: rgba(0, 0, 0, 0.06);
  border-radius: 5px;
}

.hotspot-item .issue-message-highlight-CODE {
  background-color: rgba(0, 0, 0, 0.06);
}
