/*
 * SonarQube
 * Copyright (C) 2009-2023 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  height: 24px;
  line-height: 22px;
  padding: 0 8px;
  border: 1px solid #236a97;
  border-radius: 2px;
  box-sizing: border-box;
  background: transparent;
  color: #236a97;
  font-weight: 600;
  font-size: 12px;
  text-decoration: none;
  cursor: pointer;
  outline: none;
  transition: border-color 0.2s ease, box-shadow 0.2s ease, background-color 0.2s ease;
}

.button:hover,
.button.selected {
  background: #236a97;
  color: #FFFFFF;
}

.button-primary {
  background: #236a97;
  border-color: #236a97;
  color: #FFFFFF;
}

.button-primary:hover {
  background: #0E516F;
  border-color: #0E516F;
}

.button-primary.button-light {
  background: #4b9fd5;
  border-color: #4b9fd5;
  color: #FFFFFF;
}

.button-primary.button-light:hover {
  background: #236a97;
  border-color: #236a97;
}

.button.disabled {
  color: #bbb !important;
  border-color: #ddd !important;
  background: #ebebeb !important;
  cursor: not-allowed !important;
}

/* #region .button-red */
.button-red {
  border-color: #D02F3A;
  color: #D02F3A;
}

.button-red:hover,
.button-red.active {
  background: #D02F3A;
  color: #FFFFFF;
}

/* #endregion */

/* #region .button-success */
.button-success {
  border-color: #00aa00;
  color: #00aa00;
}

.button-success:hover,
.button-success.active {
  background: #00aa00;
  color: #FFFFFF;
}

/* #endregion */

/* #region .button-plain */
.button-plain {
  box-sizing: border-box;
  background: inherit;
  color: inherit;
  cursor: pointer;
  outline: none;
  border: 0;
}

/* #endregion */

/* #region .button-link */
.button-link {
  display: inline-flex;
  height: auto;
  /* Keep this to not inherit the height from .button */
  line-height: 1;
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 0;
  background: transparent;
  color: #236a97;
  border-bottom: 1px solid rgba(35, 107, 151, 0.40);
  font-weight: 400;
  font-size: inherit;
  transition: border-color 0.2s ease, box-shadow 0.2s ease, color 0.2s ease, border-bottom 0.2s ease;
}

.dropdown .button-link {
  border-bottom: none;
}

.button-link:hover {
  background: transparent;
  color: #236a97;
  border-bottom-color: #236a97;
}

.button-link.disabled {
  color: #656565;
  background: transparent !important;
  cursor: default;
}

/* #endregion */

.button-small {
  height: 20px;
  line-height: 18px;
  padding: 0 6px;
  font-size: 11px;
}

.button-tiny {
  height: 16px;
  line-height: 16px;
  padding: 0 4px;
}

.button-large {
  height: 32px;
  padding: 0 16px;
  font-size: 14px;
}

.button-huge {
  flex-direction: column;
  padding: 16px 8px;
  width: 180px;
  height: 180px;
  background-color: #FFFFFF;
  border: solid 1px #FFFFFF;
  border-radius: 3px;
  transition: all 0.2s ease;
}

.button-huge:hover,
.button-huge:focus,
.button-huge:active {
  background-color: #FFFFFF;
  color: #236a97;
  transform: translateY(-2px);
}

/* #region .button-icon */
.button-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  width: 24px;
  height: 24px;
  padding: 0;
  border: none;
  color: inherit;
}

.button-icon.button-small {
  width: 20px;
  height: 20px;
  padding: 0;
}

.button-icon.button-small svg {
  margin-top: 0;
}

.button-icon.button-tiny {
  width: 16px;
  height: 16px;
  padding: 0;
}

.button-icon.button-tiny svg {
  margin-top: 0;
}

.button-icon:hover,
.button-icon:focus {
  background-color: currentColor;
}

.button-icon:not(.disabled):hover svg,
.button-icon:not(.disabled):focus svg {
  color: #FFFFFF;
}

.button.button-icon.disabled {
  background: transparent !important;
}

/* #endregion */

.button-list {
  display: inline-flex;
  justify-content: space-between;
  height: auto;
  border: 1px solid #e6e6e6;
  padding: 8px;
  margin: 4px;
  color: #656565;
  font-weight: normal;
}

.button-list:hover {
  background-color: white;
  border-color: #4b9fd5;
  color: #236a97;
}

.no-select {
  -webkit-user-select: none !important;
      -ms-user-select: none !important;
          user-select: none !important;
}

a[download].button.disabled {
  pointer-events: none;
}
