/*
 * SonarQube
 * Copyright (C) 2009-2023 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
*,
*:before,
*:after {
  margin: 0;
  padding: 0;
}

*:focus-visible {
  outline: 2px dotted #297BAE !important;
}

html,
body {
  background-color: #f3f3f3;
}

body {
  overflow-y: scroll;
}

fieldset,
img {
  border: 0;
}

address,
caption,
cite,
code,
dfn,
th,
var {
  font-style: normal;
  font-weight: normal;
}

caption,
th {
  text-align: left;
}

q:before,
q:after {
  content: '';
}

abbr,
acronym {
  border: 0;
  font-variant: normal;
}

input,
textarea,
select {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}

select,
input,
button,
textarea {
  font-size: inherit;
  font-family: inherit;
}

textarea {
  min-height: 40px;
}

/*Remove button padding in FF*/
select::-moz-focus-inner,
input::-moz-focus-inner,
button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

legend {
  color: #000;
}

pre,
code,
kbd,
samp,
tt {
  font-family: Consolas, 'Ubuntu Mono', 'Liberation Mono', Menlo, Courier, monospace;
  font-size: 12px;
  line-height: 18px;
}

svg,
img {
  vertical-align: top;
  overflow: visible;
}

hr {
  margin: 4px 0;
  padding: 0;
  height: 1px;
  border: none;
  background-color: #e6e6e6;
}
