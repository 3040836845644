/*
 * SonarQube
 * Copyright (C) 2009-2023 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
#create-project {
  padding-top: 0 !important;
}

#create-project header {
  padding-top: 20px;
  background-color: #f3f3f3;
  position: sticky;
  top: 48px;
  z-index: 50;
}

.create-project-mode-type-alm.disabled img {
  filter: grayscale(100%);
}

.create-project-manual {
  display: flex !important;
  justify-content: space-between;
}

.create-project-azdo-repo {
  width: 410px;
  min-height: 40px;
  box-sizing: border-box;
  margin-right: auto;
}

.create-project-import-bbs .open .boxed-group-header {
  border-bottom: 1px solid #e6e6e6;
}

.create-project-import-bbs .boxed-group-inner {
  padding-top: 24px;
}

.create-project-import-bbs-repo {
  width: 250px;
  min-height: 40px;
}

.create-project-github-repository {
  box-sizing: border-box;
  width: 33.33%;
}

.create-project-github-repository .notice {
  display: block;
  position: absolute;
}

.create-project-github-repository .notice svg {
  color: #00aa00;
}

.create-project-import table > tbody > tr > td {
  vertical-align: middle;
}

.create-project-import .project-name,
.create-project-import .project-path {
  max-width: 400px;
}

.create-project-import .sq-project-link {
  max-width: 300px;
}

.create-project-import .already-set-up svg {
  color: #00aa00;
}
