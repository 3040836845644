/*
 * SonarQube
 * Copyright (C) 2009-2023 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
ol,
ul {
  padding-left: 0;
  list-style: none;
}

.list-styled {
  margin-bottom: 10px;
  padding-left: 40px;
}

.list-styled.no-padding {
  padding-left: 16px;
}

ul.list-styled {
  list-style: disc;
}

ol.list-styled {
  list-style: decimal;
}

.list-inline {
  padding-left: 0;
  margin-left: -5px;
  list-style: none;
}

ul.list-inline > li,
div.list-inline > div {
  display: inline-block;
  vertical-align: top;
  padding-right: 5px;
  padding-left: 5px;
}

.list-spaced {
  margin-bottom: 10px;
  list-style: none;
}

.list-spaced > li {
  margin-top: 10px;
}

.list-item-checkable-link {
  cursor: pointer;
}

.list-item-checkable-link:focus {
  outline: none;
}

.list-item-checkable-link.disabled {
  opacity: 0.7;
}

.list-item-checkable-link.disabled a::before {
  background-color: #cdcdcd;
  border-color: #cdcdcd;
}

dl {
  margin-top: 0;
  margin-bottom: 20px;
}

dt,
dd {
  line-height: 1.42857143;
}

dt {
  font-weight: bold;
}

dd {
  margin-left: 0;
}
